import React, { useCallback, useState } from "react";
import { Box, TextField } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ButtonComponent from "../../@extended/button/ButtonComponent";
import "../seller/Seller.css";

import AddIcon from "@mui/icons-material/Add";
import CustomizedModal from "../../@extended/customizedmodal/CustomizedModal";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../@extended/deletemodal/DeleteModal";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import BreadCrumb from "../../@extended/breadcrumbs/Breadcrumbs";
import {
  PRODUCT_DELETE,
  PRODUCT_UPLOAD,
} from "../../../actions/productActions";
import ProductForm from "./ProductForm";
import ProductTable from "./ProductTable";
import AWS from "aws-sdk";

const ProductModule = () => {
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState({});
  const [id, setId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const userRoleAccess = useSelector(
    (state) => state?.auth?.profileDetails?.user,
  );

  const gradeDelete = useCallback((data) => {
    dispatch({ type: PRODUCT_DELETE, payload: data });
  }, []);

  // const handleUpload = (event) => {
  //   const file = event.target.files[0];
  //   if (!file) {
  //     alert("Please select a file first!");
  //     return;
  //   }
  //   console.log("file ==>", file);

  //   const formData = new FormData();
  //   formData.append("file", file);

  //   const token = cookie.get("token");
  //   // Send the file to the backend
  //   fetch(`${process.env.REACT_APP_API_URL}/admin/product/upload`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json", // Example of a common header
  //       Authorization: `Bearer ${token}`, // Example of adding an authorization token
  //     },
  //     body: formData,
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // Set success and error logs
  //       console.log(data);
  //       // setLogs(data.logs);
  //     })
  //     .catch((err) => {
  //       console.error("Error uploading file:", err);
  //     });
  // };

  const handleUpload = async (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }

    try {
      // Initialize S3 client
      const s3 = new AWS.S3({
        accessKeyId: "AKIA47CRXW4MNFSCJ3VM",
        secretAccessKey: "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",
        region: "ap-south-1",
      });

      const params = {
        Bucket: "crystal-polymer-dev",
        Key: `sheet/${selectedFile.name}`,
        Body: selectedFile,
      };

      // Upload file to S3 bucket
      const response = await s3.upload(params).promise();
      dispatch({ type: PRODUCT_UPLOAD, payload: { link: response.Location } });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const columns = [
    {
      field: "no",
      headerName: "No",
      headerClassName: "email-query-header",
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "tdsUpload",
      headerName: "TDS Upload Status",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      disableClickEventBubbling: true,
      headerClassName: "email-query-header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className="email-query-table-btn-main">
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={BorderColorIcon}
              onClick={() => {
                setOpen(true);
                setId(params?.row?.rowId);
                setRowData(params?.row);
              }}
              disabledbtn={userRoleAccess?.userPermissions?.find(
                (data) =>
                  (data?.userModule == "product" && data?.edit) ||
                  userRoleAccess?.userRoleType == "admin",
              )}
            />
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={DeleteOutlineIcon}
              onClick={() => {
                setDeleteModal(true);
                setId(params?.row?.rowId);
              }}
              disabledbtn={userRoleAccess?.userPermissions?.find(
                (data) =>
                  (data?.userModule == "product" && data?.delete) ||
                  userRoleAccess?.userRoleType == "admin",
              )}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <Box className="email-query-box">
      <BreadCrumb title="Product" perentTabName="Product" />
      <Box className="email-query-box-main">
        <Box>
          <TextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            onChange={(e) => setSearch(e.target.value)}
            sx={{ width: "400px" }}
            inputProps={{ placeholder: "Search here...." }}
          />
        </Box>
        <a
          href={
            "https://crystal-polymer-dev.s3.ap-south-1.amazonaws.com/sheet/Book1.xlsx"
          }
          target="_blank"
          rel="noreferrer"
        >
          Download Sample file
        </a>
        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={(e) => handleUpload(e)}
        />
        <ButtonComponent
          name="Add Product"
          isName={true}
          Icon={AddIcon}
          onClick={() => setOpen(true)}
          disabledbtn={userRoleAccess?.userPermissions?.find(
            (data) =>
              (data?.userModule == "product" && data?.add) ||
              userRoleAccess?.userRoleType == "admin",
          )}
        />
      </Box>
      <ProductTable columns={columns} search={search} />
      {open && (
        <CustomizedModal
          isHeadingName={true}
          heading="Product Form"
          open={open}
          onClick={() => {
            setOpen(false);
            setId("");
          }}
        >
          <ProductForm
            rowData={rowData}
            onClick={() => {
              setOpen(false);
              setId("");
            }}
            id={id}
          />
        </CustomizedModal>
      )}
      {deleteModal && (
        <DeleteModal
          isIcon={true}
          Icon={ErrorOutlineOutlinedIcon}
          open={deleteModal}
          onClick={() => {
            setDeleteModal(false);
            setId("");
          }}
          id={id}
          deleteData={gradeDelete}
          userModule="product"
        />
      )}
    </Box>
  );
};

export default ProductModule;
