// import toast from "react-hot-toast";
import { toast } from "react-hot-toast";
import {
  BRAND_ADD,
  BRAND_ADD_FAIL,
  BRAND_ADD_SUCCESS,
  BRAND_DELETE,
  BRAND_DELETE_FAIL,
  BRAND_DELETE_SUCCESS,
  BRAND_LIST,
  BRAND_LIST_FAIL,
  BRAND_LIST_SUCCESS,
  BRAND_UPDATE_FAIL,
  BRAND_SINGLE,
  BRAND_SINGLE_FAIL,
  BRAND_SINGLE_SUCCESS,
  CATEGORY_ADD,
  CATEGORY_ADD_FAIL,
  CATEGORY_ADD_SUCCESS,
  CATEGORY_DELETE,
  CATEGORY_DELETE_FAIL,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_LIST,
  CATEGORY_LIST_FAIL,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_SINGLE,
  CATEGORY_SINGLE_FAIL,
  CATEGORY_SINGLE_SUCCESS,
  CATEGORY_UPDATE,
  CATEGORY_UPDATE_FAIL,
  CATEGORY_UPDATE_SUCCESS,
  SUB_CATEGORY_ADD,
  SUB_CATEGORY_ADD_FAIL,
  SUB_CATEGORY_ADD_SUCCESS,
  SUB_CATEGORY_DELETE,
  SUB_CATEGORY_DELETE_FAIL,
  SUB_CATEGORY_DELETE_SUCCESS,
  SUB_CATEGORY_LIST,
  SUB_CATEGORY_LIST_FAIL,
  SUB_CATEGORY_LIST_SUCCESS,
  SUB_CATEGORY_SINGLE,
  SUB_CATEGORY_SINGLE_FAIL,
  SUB_CATEGORY_SINGLE_SUCCESS,
  SUB_CATEGORY_UPDATE,
  SUB_CATEGORY_UPDATE_FAIL,
  SUB_CATEGORY_UPDATE_SUCCESS,
  BRAND_UPDATE,
  BRAND_UPDATE_SUCCESS,
  GRADE_LIST,
  GRADE_LIST_SUCCESS,
  GRADE_LIST_FAIL,
  GRADE_ADD,
  GRADE_ADD_SUCCESS,
  GRADE_ADD_FAIL,
  GRADE_DELETE,
  GRADE_DELETE_SUCCESS,
  GRADE_DELETE_FAIL,
  GRADE_SINGLE,
  GRADE_SINGLE_SUCCESS,
  GRADE_SINGLE_FAIL,
  GRADE_UPDATE,
  GRADE_UPDATE_SUCCESS,
  GRADE_UPDATE_FAIL,
  PRODUCT_LIST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_ADD,
  PRODUCT_ADD_SUCCESS,
  PRODUCT_ADD_FAIL,
  PRODUCT_DELETE,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_SINGLE,
  PRODUCT_SINGLE_SUCCESS,
  PRODUCT_SINGLE_FAIL,
  PRODUCT_UPDATE,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  SELLING_PRODUCT_LIST,
  SELLING_PRODUCT_LIST_SUCCESS,
  SELLING_PRODUCT_LIST_FAIL,
  PRODUCT_SETTING_LIST,
  PRODUCT_SETTING_LIST_SUCCESS,
  PRODUCT_SETTING_LIST_FAIL,
  PRODUCT_SETTING_UPDATE,
  PRODUCT_SETTING_UPDATE_SUCCESS,
  PRODUCT_SETTING_UPDATE_FAIL,
  BUYER_ENQUIRY_SUCCESS,
  BUYER_ENQUIRY_FAIL,
  BUYER_ENQUIRY,
  MFI_LIST,
  MFI_LIST_SUCCESS,
  MFI_LIST_FAIL,
  MFI_ADD,
  MFI_ADD_SUCCESS,
  MFI_ADD_FAIL,
  MFI_DELETE,
  MFI_DELETE_SUCCESS,
  MFI_DELETE_FAIL,
  MFI_SINGLE,
  MFI_SINGLE_SUCCESS,
  MFI_SINGLE_FAIL,
  MFI_UPDATE,
  MFI_UPDATE_SUCCESS,
  MFI_UPDATE_FAIL,
  TRANSPORT_CHARGE_LIST,
  TRANSPORT_CHARGE_LIST_SUCCESS,
  TRANSPORT_CHARGE_LIST_FAIL,
  TRANSPORT_CHARGE_ADD,
  TRANSPORT_CHARGE_ADD_SUCCESS,
  TRANSPORT_CHARGE_ADD_FAIL,
  TRANSPORT_CHARGE_DELETE,
  TRANSPORT_CHARGE_DELETE_SUCCESS,
  TRANSPORT_CHARGE_DELETE_FAIL,
  TRANSPORT_CHARGE_SINGLE,
  TRANSPORT_CHARGE_SINGLE_SUCCESS,
  TRANSPORT_CHARGE_SINGLE_FAIL,
  TRANSPORT_CHARGE_UPDATE,
  TRANSPORT_CHARGE_UPDATE_SUCCESS,
  TRANSPORT_CHARGE_UPDATE_FAIL,
  BUYER_ENQUIRY_STATUS_CHANGE_FAIL,
  BUYER_ENQUIRY_STATUS_CHANGE_SUCCESS,
  BUYER_ENQUIRY_STATUS_CHANGE,
  TRANSPORT_CHARG_UPLOAD,
  TRANSPORT_CHARG_UPLOAD_SUCCESS,
  TRANSPORT_CHARG_UPLOAD_FAIL,
  PRODUCT_UPLOAD,
  PRODUCT_UPLOAD_SUCCESS,
  PRODUCT_UPLOAD_FAIL,
} from "../actions/productActions";

const initialState = {
  categoryList: {},
  categoryAdd: {},
  categoryWareHouseList: {},
  categoryWareHouseAdd: {},
  categorySingle: {},
  isLodding: false,
  error: "",
  isSuccess: false,
  categoryWareHouseSingle: {},
  isCategorySuccess: false,

  // ! sub category

  subCategoryList: {},
  subCategorySingle: {},
  isSubCategorySuccess: false,

  // ! brand section

  brandList: {},
  brandSingle: {},
  isBrandSuccess: false,

  // ! grade section

  gradeList: {},
  gradeSingle: {},
  isGradeSuccess: false,

  mfiList: {},
  mfiSingle: {},
  isMFISuccess: false,

  transportChargeList: {},
  transportChargeSingle: {},
  isTransportChargeSuccess: false,

  // ! product section

  productList: {},
  productSingle: {},
  isProductSuccess: false,
  sellingProductList: {},
  buyerEnquiryList: {},
  productSettingList: {},
  productSettingUpdate: {},
  successUploadProductList: [],
  errorUploadProductList: [],
};
const productReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CATEGORY_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case CATEGORY_LIST_SUCCESS: {
      return {
        ...state,
        categoryList: payload.data,
        isLoading: false,
      };
    }

    case CATEGORY_LIST_FAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CATEGORY_ADD: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isCategorySuccess: false,
      };
    }

    case CATEGORY_ADD_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        categoryAdd: payload.data,
        isLoading: false,
        isCategorySuccess: true,
      };
    }

    case CATEGORY_ADD_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isCategorySuccess: false,
      };
    }
    case CATEGORY_DELETE: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isCategorySuccess: false,
      };
    }

    case CATEGORY_DELETE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isCategorySuccess: true,
      };
    }

    case CATEGORY_DELETE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isCategorySuccess: false,
      };
    }
    case CATEGORY_SINGLE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case CATEGORY_SINGLE_SUCCESS: {
      return {
        ...state,
        categorySingle: payload.data,
        isLoading: false,
      };
    }

    case CATEGORY_SINGLE_FAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CATEGORY_UPDATE: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isCategorySuccess: false,
      };
    }

    case CATEGORY_UPDATE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isCategorySuccess: true,
      };
    }

    case CATEGORY_UPDATE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isCategorySuccess: false,
      };
    }

    // ! sub category reducer

    case SUB_CATEGORY_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUB_CATEGORY_LIST_SUCCESS: {
      return {
        ...state,
        subCategoryList: payload.data,
        isLoading: false,
      };
    }

    case SUB_CATEGORY_LIST_FAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUB_CATEGORY_ADD: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isSubCategorySuccess: false,
      };
    }

    case SUB_CATEGORY_ADD_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        SubCategoryAdd: payload.data,
        isLoading: false,
        isSubCategorySuccess: true,
      };
    }

    case SUB_CATEGORY_ADD_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSubCategorySuccess: false,
      };
    }
    case SUB_CATEGORY_DELETE: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isSubCategorySuccess: false,
      };
    }

    case SUB_CATEGORY_DELETE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isSubCategorySuccess: true,
      };
    }

    case SUB_CATEGORY_DELETE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isSubCategorySuccess: false,
      };
    }
    case SUB_CATEGORY_SINGLE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SUB_CATEGORY_SINGLE_SUCCESS: {
      return {
        ...state,
        subCategorySingle: payload.data,
        isLoading: false,
      };
    }

    case SUB_CATEGORY_SINGLE_FAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUB_CATEGORY_UPDATE: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isSubCategorySuccess: false,
      };
    }

    case SUB_CATEGORY_UPDATE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isSubCategorySuccess: true,
      };
    }

    case SUB_CATEGORY_UPDATE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isSubCategorySuccess: false,
      };
    }

    // ! new brands reducer

    case BRAND_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BRAND_LIST_SUCCESS: {
      return {
        ...state,
        brandList: payload.data,
        isLoading: false,
      };
    }
    case BRAND_LIST_FAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BRAND_ADD: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isBrandSuccess: false,
      };
    }
    case BRAND_ADD_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        BrandAdd: payload.data,
        isLoading: false,
        isBrandSuccess: true,
      };
    }
    case BRAND_ADD_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isBrandSuccess: false,
      };
    }
    case BRAND_DELETE: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isBrandSuccess: false,
      };
    }
    case BRAND_DELETE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isBrandSuccess: true,
      };
    }
    case BRAND_DELETE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isBrandSuccess: false,
      };
    }
    case BRAND_SINGLE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BRAND_SINGLE_SUCCESS: {
      return {
        ...state,
        brandSingle: payload.data,
        isLoading: false,
      };
    }
    case BRAND_SINGLE_FAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BRAND_UPDATE: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isBrandSuccess: false,
      };
    }
    case BRAND_UPDATE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isBrandSuccess: true,
      };
    }
    case BRAND_UPDATE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isBrandSuccess: false,
      };
    }

    // ! grade reducer
    case GRADE_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GRADE_LIST_SUCCESS: {
      return {
        ...state,
        gradeList: payload.data,
        isLoading: false,
      };
    }
    case GRADE_LIST_FAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GRADE_ADD: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isGradeSuccess: false,
      };
    }
    case GRADE_ADD_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        GradeAdd: payload.data,
        isLoading: false,
        isGradeSuccess: true,
      };
    }
    case GRADE_ADD_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isGradeSuccess: false,
      };
    }
    case GRADE_DELETE: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isGradeSuccess: false,
      };
    }
    case GRADE_DELETE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isGradeSuccess: true,
      };
    }
    case GRADE_DELETE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isGradeSuccess: false,
      };
    }
    case GRADE_SINGLE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GRADE_SINGLE_SUCCESS: {
      return {
        ...state,
        gradeSingle: payload.data,
        isLoading: false,
      };
    }
    case GRADE_SINGLE_FAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GRADE_UPDATE: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isGradeSuccess: false,
      };
    }
    case GRADE_UPDATE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isGradeSuccess: true,
      };
    }
    case GRADE_UPDATE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isGradeSuccess: false,
      };
    }

    // ! mfi reducer
    case MFI_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case MFI_LIST_SUCCESS: {
      return {
        ...state,
        mfiList: payload.data,
        isLoading: false,
      };
    }
    case MFI_LIST_FAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case MFI_ADD: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isMFISuccess: false,
      };
    }
    case MFI_ADD_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        mfiAdd: payload.data,
        isLoading: false,
        isMFISuccess: true,
      };
    }
    case MFI_ADD_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isMFISuccess: false,
      };
    }
    case MFI_DELETE: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isMFISuccess: false,
      };
    }
    case MFI_DELETE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isMFISuccess: true,
      };
    }
    case MFI_DELETE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isMFISuccess: false,
      };
    }
    case MFI_SINGLE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case MFI_SINGLE_SUCCESS: {
      return {
        ...state,
        mfiSingle: payload.data,
        isLoading: false,
      };
    }
    case MFI_SINGLE_FAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case MFI_UPDATE: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isGradeSuccess: false,
      };
    }
    case MFI_UPDATE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isMFISuccess: true,
      };
    }
    case MFI_UPDATE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isMFISuccess: false,
      };
    }

    // ! Transport Charge reducer
    case TRANSPORT_CHARGE_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case TRANSPORT_CHARGE_LIST_SUCCESS: {
      return {
        ...state,
        transportChargeList: payload.data,
        isLoading: false,
      };
    }
    case TRANSPORT_CHARGE_LIST_FAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case TRANSPORT_CHARGE_ADD: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isTransportChargeSuccess: false,
      };
    }
    case TRANSPORT_CHARGE_ADD_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isTransportChargeSuccess: true,
      };
    }
    case TRANSPORT_CHARGE_ADD_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isTransportChargeSuccess: false,
      };
    }
    case TRANSPORT_CHARG_UPLOAD: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }
    case TRANSPORT_CHARG_UPLOAD_SUCCESS: {
      toast.success(payload?.message);
      window.location.reload();
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        successUploadProductList: payload?.data?.successIds,
        errorUploadProductList: payload?.data?.errorIds,
      };
    }
    case TRANSPORT_CHARG_UPLOAD_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    }
    case TRANSPORT_CHARGE_DELETE: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isTransportChargeSuccess: false,
      };
    }
    case TRANSPORT_CHARGE_DELETE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isTransportChargeSuccess: true,
      };
    }
    case TRANSPORT_CHARGE_DELETE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isTransportChargeSuccess: false,
      };
    }
    case TRANSPORT_CHARGE_SINGLE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case TRANSPORT_CHARGE_SINGLE_SUCCESS: {
      return {
        ...state,
        transportChargeSingle: payload.data,
        isLoading: false,
      };
    }
    case TRANSPORT_CHARGE_SINGLE_FAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case TRANSPORT_CHARGE_UPDATE: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isTransportChargeSuccess: false,
      };
    }
    case TRANSPORT_CHARGE_UPDATE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isTransportChargeSuccess: true,
      };
    }
    case TRANSPORT_CHARGE_UPDATE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isTransportChargeSuccess: false,
      };
    }

    // ! Product reducer

    case PRODUCT_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PRODUCT_LIST_SUCCESS: {
      return {
        ...state,
        productList: payload.data,
        isLoading: false,
      };
    }
    case PRODUCT_LIST_FAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PRODUCT_UPLOAD: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PRODUCT_UPLOAD_SUCCESS: {
      toast.success(payload?.message);
      window.location.reload();
      return {
        ...state,
        successUploadProductList: payload?.data?.successIds,
        errorUploadProductList: payload?.data?.errorIds,
        isLoading: false,
      };
    }
    case PRODUCT_UPLOAD_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
      };
    }
    case PRODUCT_ADD: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isProductSuccess: false,
      };
    }
    case PRODUCT_ADD_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        productAdd: payload.data,
        isLoading: false,
        isProductSuccess: true,
      };
    }
    case PRODUCT_ADD_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isProductSuccess: false,
      };
    }
    case PRODUCT_DELETE: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isProductSuccess: false,
      };
    }
    case PRODUCT_DELETE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isProductSuccess: true,
      };
    }
    case PRODUCT_DELETE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isProductSuccess: false,
      };
    }
    case PRODUCT_SINGLE: {
      return {
        ...state,
        productSingle: {},
        isLoading: true,
      };
    }
    case PRODUCT_SINGLE_SUCCESS: {
      return {
        ...state,
        productSingle: payload.data,
        isLoading: false,
      };
    }
    case PRODUCT_SINGLE_FAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PRODUCT_UPDATE: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isProductSuccess: false,
      };
    }
    case PRODUCT_UPDATE_SUCCESS: {
      toast.success(payload?.message);
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isProductSuccess: true,
      };
    }
    case PRODUCT_UPDATE_FAIL: {
      toast.error(payload?.error?.data?.message);
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isProductSuccess: false,
      };
    }

    case SELLING_PRODUCT_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SELLING_PRODUCT_LIST_SUCCESS: {
      return {
        ...state,
        sellingProductList: payload.data,
        isLoading: false,
      };
    }

    case SELLING_PRODUCT_LIST_FAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case BUYER_ENQUIRY: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case BUYER_ENQUIRY_SUCCESS: {
      return {
        ...state,
        buyerEnquiryList: payload.data,
        isLoading: false,
      };
    }

    case BUYER_ENQUIRY_FAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case BUYER_ENQUIRY_STATUS_CHANGE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case BUYER_ENQUIRY_STATUS_CHANGE_SUCCESS: {
      return {
        ...state,
        // buyerEnquiryList: payload.data,
        isLoading: false,
      };
    }

    case BUYER_ENQUIRY_STATUS_CHANGE_FAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case PRODUCT_SETTING_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PRODUCT_SETTING_LIST_SUCCESS: {
      return {
        ...state,
        productSettingList: payload.data,
        isLoading: false,
      };
    }
    case PRODUCT_SETTING_LIST_FAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case PRODUCT_SETTING_UPDATE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PRODUCT_SETTING_UPDATE_SUCCESS: {
      return {
        ...state,
        productSettingUpdate: payload.data,
        isLoading: false,
      };
    }
    case PRODUCT_SETTING_UPDATE_FAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }

    default: {
      return { ...state };
    }
  }
};
export default productReducer;
