import {
  Box,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import ButtonComponent from "../../@extended/button/ButtonComponent";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { useDispatch, useSelector } from "react-redux";
import { ORDER_STATUS } from "../../../actions/orderActions";

const VehicalInformationOrder = ({ onClick, id }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme?.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const userRoleAccess = useSelector(
    (state) => state?.auth?.profileDetails?.user,
  );
  return (
    <React.Fragment>
      <Box
        className={
          isMobile ? "expense-category-main-div" : "expense-category-form-box"
        }
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            driverName: "",
            driverContact: "",
            vehicleNumber: "",
          }}
          onSubmit={(values) => {
            dispatch({
              type: ORDER_STATUS,
              payload: {
                ...values,
                orderStatus: "Order Processed",
                id: id,
                orderProcessDate: new Date(),
              },
            });
            onClick();
          }}
        >
          {({ handleBlur, handleChange, handleSubmit, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Box
                maxHeight={"calc(100vh - 300px)"}
                overflow={"auto"}
                padding="10px 5px 40px 5px"
              >
                <Grid container>
                  <Grid item xs={12} className="expense-category-form-input">
                    <Stack spacing={1}>
                      <InputLabel htmlFor="name">Driver Name*</InputLabel>
                      <OutlinedInput
                        id="driverName"
                        size="large"
                        fullWidth
                        value={values?.driverName}
                        name="driverName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Please Enter driver name"
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} className="expense-category-form-input">
                    <Stack spacing={1}>
                      <InputLabel htmlFor="name">Driver Mobile*</InputLabel>
                      <OutlinedInput
                        id="driverContact"
                        size="large"
                        fullWidth
                        value={values?.driverContact}
                        name="driverContact"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Please Enter driver mobile"
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} className="expense-category-form-input">
                    <Stack spacing={1}>
                      <InputLabel htmlFor="name">Vehicle Number*</InputLabel>
                      <OutlinedInput
                        id="vehicleNumber"
                        size="large"
                        fullWidth
                        value={values?.vehicleNumber}
                        name="vehicleNumber"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Please Enter vehicle number"
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <Box className="expense-category-form-drawer-btn-wrapper-create">
                <ButtonComponent
                  name={id ? "Update" : "Create"}
                  isName={true}
                  Icon={SaveAsIcon}
                  type="submit"
                  disabledbtn={userRoleAccess?.userPermissions?.find(
                    (data) =>
                      data?.userModule == "order" ||
                      userRoleAccess?.userRoleType == "admin",
                  )}
                />
                <ButtonComponent
                  name="Cancel"
                  isName={true}
                  className="background-white"
                  onClick={onClick}
                  disabledbtn={userRoleAccess?.userPermissions?.find(
                    (data) =>
                      data?.userModule == "order" ||
                      userRoleAccess?.userRoleType == "admin",
                  )}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </React.Fragment>
  );
};
VehicalInformationOrder.propTypes = {
  onClick: PropTypes.any,
  id: PropTypes.any,
};
export default VehicalInformationOrder;
