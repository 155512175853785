import {
  BRAND_ADD,
  BRAND_ADD_FAIL,
  BRAND_ADD_SUCCESS,
  BRAND_DELETE,
  BRAND_DELETE_FAIL,
  BRAND_DELETE_SUCCESS,
  BRAND_LIST,
  BRAND_LIST_FAIL,
  BRAND_LIST_SUCCESS,
  BRAND_UPDATE,
  BRAND_UPDATE_FAIL,
  BRAND_UPDATE_SUCCESS,
  BRAND_SINGLE,
  BRAND_SINGLE_SUCCESS,
  CATEGORY_ADD,
  CATEGORY_ADD_FAIL,
  CATEGORY_ADD_SUCCESS,
  CATEGORY_DELETE,
  CATEGORY_DELETE_FAIL,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_LIST,
  CATEGORY_LIST_FAIL,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_SINGLE,
  CATEGORY_SINGLE_FAIL,
  CATEGORY_SINGLE_SUCCESS,
  CATEGORY_UPDATE,
  CATEGORY_UPDATE_FAIL,
  CATEGORY_UPDATE_SUCCESS,
  SUB_CATEGORY_ADD,
  SUB_CATEGORY_ADD_FAIL,
  SUB_CATEGORY_ADD_SUCCESS,
  SUB_CATEGORY_DELETE,
  SUB_CATEGORY_DELETE_FAIL,
  SUB_CATEGORY_DELETE_SUCCESS,
  SUB_CATEGORY_LIST,
  SUB_CATEGORY_LIST_FAIL,
  SUB_CATEGORY_LIST_SUCCESS,
  SUB_CATEGORY_SINGLE,
  SUB_CATEGORY_SINGLE_FAIL,
  SUB_CATEGORY_SINGLE_SUCCESS,
  SUB_CATEGORY_UPDATE,
  SUB_CATEGORY_UPDATE_FAIL,
  SUB_CATEGORY_UPDATE_SUCCESS,
  BRAND_SINGLE_FAIL,
  GRADE_LIST_SUCCESS,
  GRADE_LIST_FAIL,
  GRADE_ADD_SUCCESS,
  GRADE_ADD_FAIL,
  GRADE_DELETE_SUCCESS,
  GRADE_DELETE_FAIL,
  GRADE_SINGLE_SUCCESS,
  GRADE_SINGLE_FAIL,
  GRADE_UPDATE_SUCCESS,
  GRADE_UPDATE_FAIL,
  GRADE_LIST,
  GRADE_ADD,
  GRADE_DELETE,
  GRADE_SINGLE,
  GRADE_UPDATE,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST,
  PRODUCT_ADD,
  PRODUCT_DELETE,
  PRODUCT_SINGLE,
  PRODUCT_UPDATE,
  PRODUCT_ADD_SUCCESS,
  PRODUCT_ADD_FAIL,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_SINGLE_SUCCESS,
  PRODUCT_SINGLE_FAIL,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  SELLING_PRODUCT_LIST_SUCCESS,
  SELLING_PRODUCT_LIST_FAIL,
  SELLING_PRODUCT_LIST,
  PRODUCT_SETTING_LIST,
  PRODUCT_SETTING_LIST_SUCCESS,
  PRODUCT_SETTING_LIST_FAIL,
  PRODUCT_SETTING_UPDATE,
  PRODUCT_SETTING_UPDATE_SUCCESS,
  PRODUCT_SETTING_UPDATE_FAIL,
  BUYER_ENQUIRY,
  BUYER_ENQUIRY_SUCCESS,
  BUYER_ENQUIRY_FAIL,
  MFI_LIST,
  MFI_ADD,
  MFI_DELETE,
  MFI_SINGLE,
  MFI_UPDATE,
  MFI_LIST_SUCCESS,
  MFI_LIST_FAIL,
  MFI_ADD_SUCCESS,
  MFI_ADD_FAIL,
  MFI_DELETE_SUCCESS,
  MFI_DELETE_FAIL,
  MFI_SINGLE_SUCCESS,
  MFI_SINGLE_FAIL,
  MFI_UPDATE_SUCCESS,
  MFI_UPDATE_FAIL,
  TRANSPORT_CHARGE_LIST,
  TRANSPORT_CHARGE_ADD,
  TRANSPORT_CHARGE_DELETE,
  TRANSPORT_CHARGE_SINGLE,
  TRANSPORT_CHARGE_UPDATE,
  TRANSPORT_CHARGE_LIST_SUCCESS,
  TRANSPORT_CHARGE_LIST_FAIL,
  TRANSPORT_CHARGE_ADD_SUCCESS,
  TRANSPORT_CHARGE_ADD_FAIL,
  TRANSPORT_CHARGE_DELETE_SUCCESS,
  TRANSPORT_CHARGE_DELETE_FAIL,
  TRANSPORT_CHARGE_SINGLE_SUCCESS,
  TRANSPORT_CHARGE_SINGLE_FAIL,
  TRANSPORT_CHARGE_UPDATE_SUCCESS,
  TRANSPORT_CHARGE_UPDATE_FAIL,
  BUYER_ENQUIRY_STATUS_CHANGE,
  PRODUCT_UPLOAD,
  PRODUCT_UPLOAD_SUCCESS,
  PRODUCT_UPLOAD_FAIL,
  TRANSPORT_CHARG_UPLOAD,
  TRANSPORT_CHARG_UPLOAD_SUCCESS,
  TRANSPORT_CHARG_UPLOAD_FAIL,
} from "../actions/productActions";
import { API } from "../api";
import { call, put, takeLatest } from "redux-saga/effects";

//expense saga

function* categoryList(action) {
  try {
    const response = yield call(API.categoryList, action.payload);
    yield put({ type: CATEGORY_LIST_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: CATEGORY_LIST_FAIL, payload: { error: error } });
  }
}
function* addCategoryList(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.addCategory, otherPayload);
    yield put({ type: CATEGORY_ADD_SUCCESS, payload: response.data });
    callback();
  } catch (error) {
    yield put({ type: CATEGORY_ADD_FAIL, payload: { error: error } });
  }
}
function* deleteCategory(action) {
  try {
    const response = yield call(API.deleteCategory, action.payload);
    yield put({ type: CATEGORY_DELETE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: CATEGORY_DELETE_FAIL, payload: { error: error } });
  }
}
function* singleCategory(action) {
  try {
    const response = yield call(API.singleCategory, action.payload);
    yield put({ type: CATEGORY_SINGLE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: CATEGORY_SINGLE_FAIL, payload: { error: error } });
  }
}
function* updateCategory(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.updateCategory, otherPayload);
    yield put({ type: CATEGORY_UPDATE_SUCCESS, payload: response.data });
    console.log("callback====5", callback);
    callback();
  } catch (error) {
    yield put({ type: CATEGORY_UPDATE_FAIL, payload: { error: error } });
  }
}

// ! sub-categor saga

function* subCategoryAdd(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.addSubCategory, otherPayload);
    yield put({ type: SUB_CATEGORY_ADD_SUCCESS, payload: response.data });
    callback();
  } catch (error) {
    yield put({ type: SUB_CATEGORY_ADD_FAIL, payload: { error: error } });
  }
}

function* subCategoryList(action) {
  try {
    const response = yield call(API.subCategoryList, action.payload);
    yield put({ type: SUB_CATEGORY_LIST_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: SUB_CATEGORY_LIST_FAIL, payload: { error: error } });
  }
}

function* deleteSubCategory(action) {
  try {
    const response = yield call(API.deleteSubCategory, action.payload);
    yield put({ type: SUB_CATEGORY_DELETE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: SUB_CATEGORY_DELETE_FAIL, payload: { error: error } });
  }
}
function* singleSubCategory(action) {
  try {
    const response = yield call(API.singleSubCategory, action.payload);
    yield put({ type: SUB_CATEGORY_SINGLE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: SUB_CATEGORY_SINGLE_FAIL, payload: { error: error } });
  }
}
function* updateSubCategory(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.updateSubCategory, otherPayload);
    yield put({ type: SUB_CATEGORY_UPDATE_SUCCESS, payload: response.data });
    callback();
  } catch (error) {
    yield put({ type: SUB_CATEGORY_UPDATE_FAIL, payload: { error: error } });
  }
}

// ! brand saga

function* brandAdd(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.addBrand, otherPayload);
    yield put({ type: BRAND_ADD_SUCCESS, payload: response.data });
    callback();
  } catch (error) {
    yield put({ type: BRAND_ADD_FAIL, payload: error.data });
  }
}
function* brandList(action) {
  try {
    const response = yield call(API.barndList, action.payload);
    yield put({ type: BRAND_LIST_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: BRAND_LIST_FAIL, payload: error.data });
  }
}
function* deleteBrand(action) {
  try {
    const response = yield call(API.deleteBrand, action.payload);
    yield put({ type: BRAND_DELETE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: BRAND_DELETE_FAIL, payload: error.data });
  }
}
function* singleBrand(action) {
  try {
    const response = yield call(API.singleBrand, action.payload);
    yield put({ type: BRAND_SINGLE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: BRAND_SINGLE_FAIL, payload: error.data });
  }
}
function* updateBrand(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.updateBrand, otherPayload);
    yield put({ type: BRAND_UPDATE_SUCCESS, payload: response.data });
    callback();
  } catch (error) {
    yield put({ type: BRAND_UPDATE_FAIL, payload: error.data });
  }
}

// mfi sagas
function* mfiList(action) {
  try {
    const response = yield call(API.mfiList, action.payload);
    yield put({ type: MFI_LIST_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: MFI_LIST_FAIL, payload: error.data });
  }
}
function* addMFI(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.addMFI, otherPayload);
    yield put({ type: MFI_ADD_SUCCESS, payload: response.data });
    callback();
  } catch (error) {
    yield put({ type: MFI_ADD_FAIL, payload: error.data });
  }
}
function* deleteMFI(action) {
  try {
    const response = yield call(API.deleteMFI, action.payload);
    yield put({ type: MFI_DELETE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: MFI_DELETE_FAIL, payload: error.data });
  }
}
function* singleMFI(action) {
  try {
    const response = yield call(API.singleMFI, action.payload);
    yield put({ type: MFI_SINGLE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: MFI_SINGLE_FAIL, payload: error.data });
  }
}
function* updateMFI(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.updateMFI, otherPayload);
    yield put({ type: MFI_UPDATE_SUCCESS, payload: response.data });
    callback();
  } catch (error) {
    yield put({ type: MFI_UPDATE_FAIL, payload: error.data });
  }
}

// TRANSPORT CHARGE sagas
function* transportChargeList(action) {
  try {
    const response = yield call(API.transportChargeList, action.payload);
    yield put({ type: TRANSPORT_CHARGE_LIST_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: TRANSPORT_CHARGE_LIST_FAIL, payload: error.data });
  }
}
function* addTransportCharge(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.addTransportCharge, otherPayload);
    yield put({ type: TRANSPORT_CHARGE_ADD_SUCCESS, payload: response.data });
    callback();
  } catch (error) {
    yield put({ type: TRANSPORT_CHARGE_ADD_FAIL, payload: error.data });
  }
}
function* deleteTransportCharge(action) {
  try {
    const response = yield call(API.deleteTransportCharge, action.payload);
    yield put({
      type: TRANSPORT_CHARGE_DELETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: TRANSPORT_CHARGE_DELETE_FAIL, payload: error.data });
  }
}
function* uploadTransportCharge(action) {
  try {
    const response = yield call(API.uploadTransportCharge, action.payload);
    yield put({
      type: TRANSPORT_CHARG_UPLOAD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: TRANSPORT_CHARG_UPLOAD_FAIL, payload: error.data });
  }
}
function* singleTransportCharge(action) {
  try {
    const response = yield call(API.singleTransportCharge, action.payload);
    yield put({
      type: TRANSPORT_CHARGE_SINGLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: TRANSPORT_CHARGE_SINGLE_FAIL, payload: error.data });
  }
}
function* updateTransportCharge(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.updateTransportCharge, otherPayload);
    yield put({
      type: TRANSPORT_CHARGE_UPDATE_SUCCESS,
      payload: response.data,
    });
    callback();
  } catch (error) {
    yield put({ type: TRANSPORT_CHARGE_UPDATE_FAIL, payload: error.data });
  }
}

// !grade saga
function* gradeList(action) {
  try {
    const response = yield call(API.gradeList, action.payload);
    yield put({ type: GRADE_LIST_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: GRADE_LIST_FAIL, payload: error.data });
  }
}
function* addGrade(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.addGrade, otherPayload);
    yield put({ type: GRADE_ADD_SUCCESS, payload: response.data });
    callback();
  } catch (error) {
    yield put({ type: GRADE_ADD_FAIL, payload: error.data });
  }
}
function* deleteGrade(action) {
  try {
    const response = yield call(API.deleteGrade, action.payload);
    yield put({ type: GRADE_DELETE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: GRADE_DELETE_FAIL, payload: error.data });
  }
}
function* singleGrade(action) {
  try {
    const response = yield call(API.singleGrade, action.payload);
    yield put({ type: GRADE_SINGLE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: GRADE_SINGLE_FAIL, payload: error.data });
  }
}
function* updateGrade(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.updateGrade, otherPayload);
    yield put({ type: GRADE_UPDATE_SUCCESS, payload: response.data });
    callback();
  } catch (error) {
    yield put({ type: GRADE_UPDATE_FAIL, payload: error.data });
  }
}

// ! product sagas

function* productList(action) {
  try {
    const response = yield call(API.productList, action.payload);
    yield put({ type: PRODUCT_LIST_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: PRODUCT_LIST_FAIL, payload: error.data });
  }
}
function* addProduct(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.addProduct, otherPayload);
    yield put({ type: PRODUCT_ADD_SUCCESS, payload: response.data });
    callback();
  } catch (error) {
    yield put({ type: PRODUCT_ADD_FAIL, payload: error.data });
  }
}
function* deleteProduct(action) {
  try {
    const response = yield call(API.deleteProduct, action.payload);
    yield put({ type: PRODUCT_DELETE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: PRODUCT_DELETE_FAIL, payload: error.data });
  }
}
function* uploadProduct(action) {
  try {
    const response = yield call(API.uploadProduct, action.payload);
    yield put({ type: PRODUCT_UPLOAD_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: PRODUCT_UPLOAD_FAIL, payload: error.data });
  }
}
function* singleProduct(action) {
  try {
    const response = yield call(API.singleProduct, action.payload);
    yield put({ type: PRODUCT_SINGLE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: PRODUCT_SINGLE_FAIL, payload: error.data });
  }
}
function* updateProduct(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.updateProduct, otherPayload);
    yield put({ type: PRODUCT_UPDATE_SUCCESS, payload: response.data });
    callback();
  } catch (error) {
    yield put({ type: PRODUCT_UPDATE_FAIL, payload: error.data });
  }
}
function* sellingProduct(action) {
  try {
    const response = yield call(API.sellingProduct, action.payload);
    yield put({ type: SELLING_PRODUCT_LIST_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: SELLING_PRODUCT_LIST_FAIL, payload: error.data });
  }
}

function* buyerEnquiry(action) {
  try {
    const response = yield call(API.buyerEnquiry, action.payload);
    yield put({ type: BUYER_ENQUIRY_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: BUYER_ENQUIRY_FAIL, payload: error.data });
  }
}

function* buyerEnquiryStatusChange(action) {
  try {
    const response = yield call(API.buyerEnquiryStatusChange, action.payload);
    yield put({ type: BUYER_ENQUIRY_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: BUYER_ENQUIRY_FAIL, payload: error.data });
  }
}

function* productSettingList(action) {
  try {
    const response = yield call(API.productSettingList, action.payload);
    yield put({ type: PRODUCT_SETTING_LIST_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: PRODUCT_SETTING_LIST_FAIL, payload: error.data });
  }
}

function* updateProductSetting(action) {
  try {
    const { callback, ...otherPayload } = action.payload;
    const response = yield call(API.updateProductSetting, otherPayload);
    yield put({ type: PRODUCT_SETTING_UPDATE_SUCCESS, payload: response.data });
    callback();
  } catch (error) {
    yield put({ type: PRODUCT_SETTING_UPDATE_FAIL, payload: error.data });
  }
}
function* product() {
  yield takeLatest(CATEGORY_LIST, categoryList);
  yield takeLatest(CATEGORY_ADD, addCategoryList);
  yield takeLatest(CATEGORY_DELETE, deleteCategory);
  yield takeLatest(CATEGORY_SINGLE, singleCategory);
  yield takeLatest(CATEGORY_UPDATE, updateCategory);
  yield takeLatest(SUB_CATEGORY_ADD, subCategoryAdd);
  yield takeLatest(SUB_CATEGORY_LIST, subCategoryList);
  yield takeLatest(SUB_CATEGORY_DELETE, deleteSubCategory);
  yield takeLatest(SUB_CATEGORY_SINGLE, singleSubCategory);
  yield takeLatest(SUB_CATEGORY_UPDATE, updateSubCategory);
  yield takeLatest(BRAND_ADD, brandAdd);
  yield takeLatest(BRAND_LIST, brandList);
  yield takeLatest(BRAND_DELETE, deleteBrand);
  yield takeLatest(BRAND_SINGLE, singleBrand);
  yield takeLatest(BRAND_UPDATE, updateBrand);
  yield takeLatest(GRADE_LIST, gradeList);
  yield takeLatest(GRADE_ADD, addGrade);
  yield takeLatest(GRADE_DELETE, deleteGrade);
  yield takeLatest(GRADE_SINGLE, singleGrade);
  yield takeLatest(GRADE_UPDATE, updateGrade);

  yield takeLatest(MFI_LIST, mfiList);
  yield takeLatest(MFI_ADD, addMFI);
  yield takeLatest(MFI_DELETE, deleteMFI);
  yield takeLatest(MFI_SINGLE, singleMFI);
  yield takeLatest(MFI_UPDATE, updateMFI);

  yield takeLatest(TRANSPORT_CHARGE_LIST, transportChargeList);
  yield takeLatest(TRANSPORT_CHARGE_ADD, addTransportCharge);
  yield takeLatest(TRANSPORT_CHARGE_DELETE, deleteTransportCharge);
  yield takeLatest(TRANSPORT_CHARG_UPLOAD, uploadTransportCharge);
  yield takeLatest(TRANSPORT_CHARGE_SINGLE, singleTransportCharge);
  yield takeLatest(TRANSPORT_CHARGE_UPDATE, updateTransportCharge);

  yield takeLatest(PRODUCT_LIST, productList);
  yield takeLatest(PRODUCT_ADD, addProduct);
  yield takeLatest(PRODUCT_DELETE, deleteProduct);
  yield takeLatest(PRODUCT_UPLOAD, uploadProduct);
  yield takeLatest(PRODUCT_SINGLE, singleProduct);
  yield takeLatest(PRODUCT_UPDATE, updateProduct);
  yield takeLatest(SELLING_PRODUCT_LIST, sellingProduct);
  yield takeLatest(BUYER_ENQUIRY, buyerEnquiry);
  yield takeLatest(BUYER_ENQUIRY_STATUS_CHANGE, buyerEnquiryStatusChange);

  yield takeLatest(PRODUCT_SETTING_LIST, productSettingList);
  yield takeLatest(PRODUCT_SETTING_UPDATE, updateProductSetting);
}
export default product;
