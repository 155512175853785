import { deleteRequest, get, patch, post } from "../utils/api";

export const categoryList = (data) => {
  const { pageState = 1, limitState = 10, search = "" } = data;
  return get(
    `/admin/category?page=${pageState}&limit=${limitState}&search=${search}`,
  );
};
export const addCategory = (data) => {
  return post(`/admin/category/`, data);
};
export const deleteCategory = (id) => {
  return deleteRequest(`/admin/category/${id}`);
};
export const singleCategory = (id) => {
  return get(`/admin/category/${id}`);
};
export const updateCategory = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/admin/category/${id}`, data);
};

// ! sub-category API
export const subCategoryList = (data) => {
  const { pageState = 1, limitState = 10, search = "" } = data;
  return get(
    `/admin/subcategory?page=${pageState}&limit=${limitState}&search=${search}`,
  );
};
export const addSubCategory = (data) => {
  return post(`/admin/subcategory/`, data);
};
export const deleteSubCategory = (id) => {
  return deleteRequest(`/admin/subcategory/${id}`);
};
export const singleSubCategory = (id) => {
  return get(`/admin/subcategory/${id}`);
};
export const updateSubCategory = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/admin/subcategory/${id}`, data);
};

// ! brand apis

export const barndList = (data) => {
  const { pageState = 1, limitState = 10, search = "" } = data;
  return get(
    `/admin/brand?page=${pageState}&limit=${limitState}&search=${search}`,
  );
};
export const addBrand = (data) => {
  return post(`/admin/brand/`, data);
};
export const deleteBrand = (id) => {
  return deleteRequest(`/admin/brand/${id}`);
};
export const singleBrand = (id) => {
  return get(`/admin/brand/${id}`);
};
export const updateBrand = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/admin/brand/${id}`, data);
};

// ! grade API here
export const gradeList = (data) => {
  const { pageState = 1, limitState = 10, search = "" } = data;
  return get(
    `/admin/grade?page=${pageState}&limit=${limitState}&search=${search}`,
  );
};
export const addGrade = (data) => {
  return post(`/admin/grade/`, data);
};
export const deleteGrade = (id) => {
  return deleteRequest(`/admin/grade/${id}`);
};
export const singleGrade = (id) => {
  return get(`/admin/grade/${id}`);
};
export const updateGrade = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/admin/grade/${id}`, data);
};

// ! mfi API here
export const mfiList = (data) => {
  const { pageState = 1, limitState = 10, search = "" } = data;
  return get(
    `/admin/mfi?page=${pageState}&limit=${limitState}&search=${search}`,
  );
};
export const addMFI = (data) => {
  return post(`/admin/mfi/`, data);
};
export const deleteMFI = (id) => {
  return deleteRequest(`/admin/mfi/${id}`);
};
export const singleMFI = (id) => {
  return get(`/admin/mfi/${id}`);
};
export const updateMFI = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/admin/mfi/${id}`, data);
};

// ! transport charge API here
export const transportChargeList = (data) => {
  const { pageState = 1, limitState = 10, search = "" } = data;
  return get(
    `/admin/transport-charge?page=${pageState}&limit=${limitState}&search=${search}`,
  );
};
export const addTransportCharge = (data) => {
  return post(`/admin/transport-charge/`, data);
};
export const deleteTransportCharge = (id) => {
  return deleteRequest(`/admin/transport-charge/${id}`);
};
export const uploadTransportCharge = (data) => {
  return post(`/admin/transport-charge/upload`, data);
};
export const singleTransportCharge = (id) => {
  return get(`/admin/transport-charge/${id}`);
};
export const updateTransportCharge = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/admin/transport-charge/${id}`, data);
};

// ! Product API here

export const productList = (data) => {
  const { pageState = 1, limitState = 10, search = "" } = data;
  return get(
    `/admin/product?page=${pageState}&limit=${limitState}&search=${search}`,
  );
};
export const addProduct = (data) => {
  return post(`/admin/product/`, data);
};
export const deleteProduct = (id) => {
  return deleteRequest(`/admin/product/${id}`);
};
export const uploadProduct = (data) => {
  return post(`/admin/product/upload`, data);
};
export const singleProduct = (id) => {
  return get(`/admin/product/${id}`);
};
export const updateProduct = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/admin/product/${id}`, data);
};
// ! selling product api

export const sellingProduct = (data) => {
  return get(`/admin/product/selling`, data);
};

export const buyerEnquiry = (data) => {
  return get(`/admin/enquiry`, data);
};

export const buyerEnquiryStatusChange = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/admin/enquiry/status-change/${id}`, data);
};

export const productSettingList = () => {
  return get(`/admin/setting`);
};

export const updateProductSetting = (data) => {
  const id = data?.id;
  delete data["id"];
  return patch(`/admin/setting/${id}`, data);
};

export const productApi = {
  categoryList,
  addCategory,
  deleteCategory,
  singleCategory,
  updateCategory,

  // ! sub-category API

  subCategoryList,
  addSubCategory,
  deleteSubCategory,
  singleSubCategory,
  updateSubCategory,

  // !brands api
  barndList,
  addBrand,
  deleteBrand,
  singleBrand,
  updateBrand,

  // ! grade APi
  gradeList,
  addGrade,
  deleteGrade,
  singleGrade,
  updateGrade,

  // ! mfi APi
  mfiList,
  addMFI,
  deleteMFI,
  singleMFI,
  updateMFI,

  // ! transport charge APi
  transportChargeList,
  addTransportCharge,
  deleteTransportCharge,
  singleTransportCharge,
  updateTransportCharge,
  uploadTransportCharge,

  // ! product APi
  productList,
  addProduct,
  deleteProduct,
  uploadProduct,
  singleProduct,
  updateProduct,
  sellingProduct,
  productSettingList,
  updateProductSetting,
  buyerEnquiry,
  buyerEnquiryStatusChange,
};
