import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import CommonTable from "../../@extended/table/CommonTable";
import { useDispatch, useSelector } from "react-redux";
import { PRODUCT_LIST } from "../../../actions/productActions";

const ProductTable = (props) => {
  const { columns, search } = props;
  const dispatch = useDispatch();

  const productListData = useSelector((data) => data?.product?.productList);
  const isProductSuccess = useSelector(
    (data) => data?.product?.isProductSuccess,
  );
  const { totalResults = 0, limit, page } = productListData;
  const [limitState, setLimit] = useState(10);
  const [pageState, setPage] = useState(1);

  const productList = useCallback((values) => {
    dispatch({ type: PRODUCT_LIST, payload: values });
  }, []);
  const rows = productListData?.results?.map((item, ind) => {
    return {
      id: ind + 1,
      no: ind + 1,
      name: item?.name,
      brand: item?.brand,
      category: item?.category,
      grade: item?.grade,
      mfi: item?.mfi,
      subCategory: item?.subCategory,
      tdsSheetLink: item?.tdsSheetLink,
      tdsUpload: item?.tdsSheetLink ? "Yes" : "No",
      actions: "",
      rowId: item?.id,
    };
  });

  useEffect(() => {
    if (productListData?.limit) {
      setLimit(productListData?.limit);
    }
    if (productListData?.page) {
      setPage(productListData?.page);
    }
  }, [productListData?.limit, productListData?.page]);

  useEffect(() => {
    productList({ limitState, pageState, search });
  }, [limitState, pageState, isProductSuccess, search]);

  return (
    <Box className="email-query-box">
      <Box className="email-query-common-table-box-main">
        <Box className="email-query-common-table-box">
          <CommonTable
            columns={columns}
            rows={rows?.length > 0 ? rows : []}
            ischeck={false}
            isPagination={true}
            pageData={{ limit, page, totalResults, setLimit, setPage }}
          />
        </Box>
      </Box>
    </Box>
  );
};
ProductTable.propTypes = {
  columns: PropTypes.any,
  type: PropTypes.any,
  search: PropTypes.any,
  setDeleteModal: PropTypes.any,
};
export default ProductTable;
