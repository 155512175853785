export const CATEGORY_ADD = "CATEGORY_ADD";
export const CATEGORY_ADD_SUCCESS = "CATEGORY_ADD_SUCCESS";
export const CATEGORY_ADD_FAIL = "CATEGORY_ADD_FAIL";

export const CATEGORY_LIST = "CATEGORY_LIST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_FAIL = "CATEGORY_LIST_FAIL";

export const CATEGORY_DELETE = "CATEGORY_DELETE";
export const CATEGORY_DELETE_SUCCESS = "CATEGORY_DELETE_SUCCESS";
export const CATEGORY_DELETE_FAIL = "CATEGORY_DELETE_FAIL";

export const CATEGORY_SINGLE = "CATEGORY_SINGLE";
export const CATEGORY_SINGLE_SUCCESS = "CATEGORY_SINGLE_SUCCESS";
export const CATEGORY_SINGLE_FAIL = "CATEGORY_SINGLE_FAIL";

export const CATEGORY_UPDATE = "CATEGORY_UPDATE";
export const CATEGORY_UPDATE_SUCCESS = "CATEGORY_UPDATE_SUCCESS";
export const CATEGORY_UPDATE_FAIL = "CATEGORY_UPDATE_FAIL";

export const CATEGORY_SINGLE_UPDATE = "CATEGORY_SINGLE_UPDATE";
export const CATEGORY_SINGLE_UPDATE_SUCCESS = "CATEGORY_SINGLE_UPDATE_SUCCESS";
export const CATEGORY_SINGLE_UPDATE_FAIL = "CATEGORY_SINGLE_UPDATE_FAIL";

// ! sub-category all actions here

export const SUB_CATEGORY_ADD = "SUB_CATEGORY_ADD";
export const SUB_CATEGORY_ADD_SUCCESS = "SUB_CATEGORY_ADD_SUCCESS";
export const SUB_CATEGORY_ADD_FAIL = "SUB_CATEGORY_ADD_FAIL";

export const SUB_CATEGORY_LIST = "SUB_CATEGORY_LIST";
export const SUB_CATEGORY_LIST_SUCCESS = "SUB_CATEGORY_LIST_SUCCESS";
export const SUB_CATEGORY_LIST_FAIL = "SUB_CATEGORY_LIST_FAIL";

export const SUB_CATEGORY_DELETE = "SUB_CATEGORY_DELETE";
export const SUB_CATEGORY_DELETE_SUCCESS = "SUB_CATEGORY_DELETE_SUCCESS";
export const SUB_CATEGORY_DELETE_FAIL = "SUB_CATEGORY_DELETE_FAIL";

export const SUB_CATEGORY_SINGLE = "SUB_CATEGORY_SINGLE";
export const SUB_CATEGORY_SINGLE_SUCCESS = "SUB_CATEGORY_SINGLE_SUCCESS";
export const SUB_CATEGORY_SINGLE_FAIL = "SUB_CATEGORY_SINGLE_FAIL";

export const SUB_CATEGORY_UPDATE = "SUB_CATEGORY_UPDATE";
export const SUB_CATEGORY_UPDATE_SUCCESS = "SUB_CATEGORY_UPDATE_SUCCESS";
export const SUB_CATEGORY_UPDATE_FAIL = "SUB_CATEGORY_UPDATE_FAIL";

export const SUB_CATEGORY_SINGLE_UPDATE = "SUB_CATEGORY_SINGLE_UPDATE";
export const SUB_CATEGORY_SINGLE_UPDATE_SUCCESS =
  "SUB_CATEGORY_SINGLE_UPDATE_SUCCESS";
export const SUB_CATEGORY_SINGLE_UPDATE_FAIL =
  "SUB_CATEGORY_SINGLE_UPDATE_FAIL";

// ! brand actions

export const BRAND_ADD = "BRAND_ADD";
export const BRAND_ADD_SUCCESS = "BRAND_ADD_SUCCESS";
export const BRAND_ADD_FAIL = "BRAND_ADD_FAIL";

export const BRAND_LIST = "BRAND_LIST";
export const BRAND_LIST_SUCCESS = "BRAND_LIST_SUCCESS";
export const BRAND_LIST_FAIL = "BRAND_LIST_FAIL";

export const BRAND_DELETE = "BRAND_DELETE";
export const BRAND_DELETE_SUCCESS = "BRAND_DELETE_SUCCESS";
export const BRAND_DELETE_FAIL = "BRAND_DELETE_FAIL";

export const BRAND_SINGLE = "BRAND_SINGLE";
export const BRAND_SINGLE_SUCCESS = "BRAND_SINGLE_SUCCESS";
export const BRAND_SINGLE_FAIL = "BRAND_SINGLE_FAIL";

export const BRAND_UPDATE = "BRAND_UPDATE";
export const BRAND_UPDATE_SUCCESS = "BRAND_UPDATE_SUCCESS";
export const BRAND_UPDATE_FAIL = "BRAND_UPDATE_FAIL";

// ! MFI action
export const MFI_ADD = "MFI_ADD";
export const MFI_ADD_SUCCESS = "MFI_ADD_SUCCESS";
export const MFI_ADD_FAIL = "MFI_ADD_FAIL";

export const MFI_LIST = "MFI_LIST";
export const MFI_LIST_SUCCESS = "MFI_LIST_SUCCESS";
export const MFI_LIST_FAIL = "MFI_LIST_FAIL";

export const MFI_DELETE = "MFI_DELETE";
export const MFI_DELETE_SUCCESS = "MFI_DELETE_SUCCESS";
export const MFI_DELETE_FAIL = "MFI_DELETE_FAIL";

export const MFI_SINGLE = "MFI_SINGLE";
export const MFI_SINGLE_SUCCESS = "MFI_SINGLE_SUCCESS";
export const MFI_SINGLE_FAIL = "MFI_SINGLE_FAIL";

export const MFI_UPDATE = "MFI_UPDATE";
export const MFI_UPDATE_SUCCESS = "MFI_UPDATE_SUCCESS";
export const MFI_UPDATE_FAIL = "MFI_UPDATE_FAIL";

// ! TRANSPORT_CHARGE action
export const TRANSPORT_CHARGE_ADD = "TRANSPORT_CHARGE_ADD";
export const TRANSPORT_CHARGE_ADD_SUCCESS = "TRANSPORT_CHARGE_ADD_SUCCESS";
export const TRANSPORT_CHARGE_ADD_FAIL = "TRANSPORT_CHARGE_ADD_FAIL";

export const TRANSPORT_CHARGE_LIST = "TRANSPORT_CHARGE_LIST";
export const TRANSPORT_CHARGE_LIST_SUCCESS = "TRANSPORT_CHARGE_LIST_SUCCESS";
export const TRANSPORT_CHARGE_LIST_FAIL = "TRANSPORT_CHARGE_LIST_FAIL";

export const TRANSPORT_CHARGE_DELETE = "TRANSPORT_CHARGE_DELETE";
export const TRANSPORT_CHARGE_DELETE_SUCCESS =
  "TRANSPORT_CHARGE_DELETE_SUCCESS";
export const TRANSPORT_CHARGE_DELETE_FAIL = "TRANSPORT_CHARGE_DELETE_FAIL";

export const TRANSPORT_CHARG_UPLOAD = "TRANSPORT_CHARG_UPLOAD";
export const TRANSPORT_CHARG_UPLOAD_SUCCESS = "TRANSPORT_CHARG_UPLOAD_SUCCESS";
export const TRANSPORT_CHARG_UPLOAD_FAIL = "TRANSPORT_CHARG_UPLOAD_FAIL";

export const TRANSPORT_CHARGE_SINGLE = "TRANSPORT_CHARGE_SINGLE";
export const TRANSPORT_CHARGE_SINGLE_SUCCESS =
  "TRANSPORT_CHARGE_SINGLE_SUCCESS";
export const TRANSPORT_CHARGE_SINGLE_FAIL = "TRANSPORT_CHARGE_SINGLE_FAIL";

export const TRANSPORT_CHARGE_UPDATE = "TRANSPORT_CHARGE_UPDATE";
export const TRANSPORT_CHARGE_UPDATE_SUCCESS =
  "TRANSPORT_CHARGE_UPDATE_SUCCESS";
export const TRANSPORT_CHARGE_UPDATE_FAIL = "TRANSPORT_CHARGE_UPDATE_FAIL";

// ! grade action
export const GRADE_ADD = "GRADE_ADD";
export const GRADE_ADD_SUCCESS = "GRADE_ADD_SUCCESS";
export const GRADE_ADD_FAIL = "GRADE_ADD_FAIL";

export const GRADE_LIST = "GRADE_LIST";
export const GRADE_LIST_SUCCESS = "GRADE_LIST_SUCCESS";
export const GRADE_LIST_FAIL = "GRADE_LIST_FAIL";

export const GRADE_DELETE = "GRADE_DELETE";
export const GRADE_DELETE_SUCCESS = "GRADE_DELETE_SUCCESS";
export const GRADE_DELETE_FAIL = "GRADE_DELETE_FAIL";

export const GRADE_SINGLE = "GRADE_SINGLE";
export const GRADE_SINGLE_SUCCESS = "GRADE_SINGLE_SUCCESS";
export const GRADE_SINGLE_FAIL = "GRADE_SINGLE_FAIL";

export const GRADE_UPDATE = "GRADE_UPDATE";
export const GRADE_UPDATE_SUCCESS = "GRADE_UPDATE_SUCCESS";
export const GRADE_UPDATE_FAIL = "GRADE_UPDATE_FAIL";

// ! product actions

export const PRODUCT_ADD = "PRODUCT_ADD";
export const PRODUCT_ADD_SUCCESS = "PRODUCT_ADD_SUCCESS";
export const PRODUCT_ADD_FAIL = "PRODUCT_ADD_FAIL";

export const PRODUCT_LIST = "PRODUCT_LIST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

export const PRODUCT_UPLOAD = "PRODUCT_UPLOAD";
export const PRODUCT_UPLOAD_SUCCESS = "PRODUCT_UPLOAD_SUCCESS";
export const PRODUCT_UPLOAD_FAIL = "PRODUCT_UPLOAD_FAIL";

export const PRODUCT_DELETE = "PRODUCT_DELETE";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAIL = "PRODUCT_DELETE_FAIL";

export const PRODUCT_SINGLE = "PRODUCT_SINGLE";
export const PRODUCT_SINGLE_SUCCESS = "PRODUCT_SINGLE_SUCCESS";
export const PRODUCT_SINGLE_FAIL = "PRODUCT_SINGLE_FAIL";

export const PRODUCT_UPDATE = "PRODUCT_UPDATE";
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS";
export const PRODUCT_UPDATE_FAIL = "PRODUCT_UPDATE_FAIL";

export const SELLING_PRODUCT_LIST = "SELLING_PRODUCT_LIST";
export const SELLING_PRODUCT_LIST_SUCCESS = "SELLING_PRODUCT_LIST_SUCCESS";
export const SELLING_PRODUCT_LIST_FAIL = "SELLING_PRODUCT_LIST_FAIL";

export const BUYER_ENQUIRY = "BUYER_ENQUIRY";
export const BUYER_ENQUIRY_SUCCESS = "BUYER_ENQUIRY_SUCCESS";
export const BUYER_ENQUIRY_FAIL = "BUYER_ENQUIRY_FAIL";

export const BUYER_ENQUIRY_STATUS_CHANGE = "BUYER_ENQUIRY_STATUS_CHANGE";
export const BUYER_ENQUIRY_STATUS_CHANGE_SUCCESS =
  "BUYER_ENQUIRY_STATUS_CHANGE_SUCCESS";
export const BUYER_ENQUIRY_STATUS_CHANGE_FAIL =
  "BUYER_ENQUIRY_STATUS_CHANGE_FAIL";

export const PRODUCT_SETTING_LIST = "PRODUCT_SETTING_LIST";
export const PRODUCT_SETTING_LIST_SUCCESS = "PRODUCT_SETTING_LIST_SUCCESS";
export const PRODUCT_SETTING_LIST_FAIL = "PRODUCT_SETTING_LIST_FAIL";

export const PRODUCT_SETTING_UPDATE = "PRODUCT_SETTING_UPDATE";
export const PRODUCT_SETTING_UPDATE_SUCCESS = "PRODUCT_SETTING_UPDATE_SUCCESS";
export const PRODUCT_SETTING_UPDATE_FAIL = "PRODUCT_SETTING_UPDATE_FAIL";
