import React, { useCallback, useState } from "react";
import { Box, TextField } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ButtonComponent from "../../@extended/button/ButtonComponent";
import "../seller/Seller.css";
import AddIcon from "@mui/icons-material/Add";
import CustomizedModal from "../../@extended/customizedmodal/CustomizedModal";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../@extended/deletemodal/DeleteModal";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import BreadCrumb from "../../@extended/breadcrumbs/Breadcrumbs";
import {
  TRANSPORT_CHARG_UPLOAD,
  TRANSPORT_CHARGE_DELETE,
} from "../../../actions/productActions";
import TransportChargeForm from "./TransportChargeForm";
import TransportChargeTable from "./TransportChargeTable";
import AWS from "aws-sdk";

const TransportChargeModule = () => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();

  const userRoleAccess = useSelector(
    (state) => state?.auth?.profileDetails?.user,
  );

  const transportChargeDelete = useCallback((data) => {
    dispatch({ type: TRANSPORT_CHARGE_DELETE, payload: data });
  }, []);

  const columns = [
    {
      field: "no",
      headerName: "No",
      headerClassName: "email-query-header",
    },
    {
      field: "fromLocation",
      headerName: "From Location",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "toLocation",
      headerName: "To Location",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "rate20",
      headerName: "Rate 30",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "rate",
      headerName: "Rate 25",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "rate15",
      headerName: "Rate 18",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "rate10",
      headerName: "Rate 12",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "rate5",
      headerName: "Rate 5",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      disableClickEventBubbling: true,
      headerClassName: "email-query-header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className="email-query-table-btn-main">
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={BorderColorIcon}
              onClick={() => {
                setOpen(true);
                setId(params?.row?.rowId);
              }}
              disabledbtn={userRoleAccess?.userPermissions?.find(
                (data) =>
                  (data?.userModule == "grade" && data?.edit) ||
                  userRoleAccess?.userRoleType == "admin",
              )}
            />
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={DeleteOutlineIcon}
              onClick={() => {
                setDeleteModal(true);
                setId(params?.row?.rowId);
              }}
              disabledbtn={userRoleAccess?.userPermissions?.find(
                (data) =>
                  (data?.userModule == "grade" && data?.delete) ||
                  userRoleAccess?.userRoleType == "admin",
              )}
            />
          </Box>
        );
      },
    },
  ];

  const handleUpload = async (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }

    try {
      // Initialize S3 client
      const s3 = new AWS.S3({
        accessKeyId: "AKIA47CRXW4MNFSCJ3VM",
        secretAccessKey: "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",
        region: "ap-south-1",
      });

      const params = {
        Bucket: "crystal-polymer-dev",
        Key: `sheet/${selectedFile.name}`,
        Body: selectedFile,
      };

      // Upload file to S3 bucket
      const response = await s3.upload(params).promise();
      dispatch({
        type: TRANSPORT_CHARG_UPLOAD,
        payload: { link: response.Location },
      });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <Box className="email-query-box">
      <BreadCrumb title="Transport Charge" perentTabName="Transport Charge" />
      <Box className="email-query-box-main">
        <Box>
          <TextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            onChange={(e) => setSearch(e.target.value)}
            sx={{ width: "400px" }}
            inputProps={{ placeholder: "Search here...." }}
          />
        </Box>
        <a
          href={
            "https://crystal-polymer-dev.s3.ap-south-1.amazonaws.com/sheet/Book2.xlsx"
          }
          target="_blank"
          rel="noreferrer"
        >
          Download Sample file
        </a>
        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={(e) => handleUpload(e)}
        />
        <ButtonComponent
          name="Add Transport Charge"
          isName={true}
          Icon={AddIcon}
          onClick={() => setOpen(true)}
          disabledbtn={userRoleAccess?.userPermissions?.find(
            (data) =>
              (data?.userModule == "grade" && data?.add) ||
              userRoleAccess?.userRoleType == "admin",
          )}
        />
      </Box>
      <TransportChargeTable columns={columns} search={search} />
      {open && (
        <CustomizedModal
          isHeadingName={true}
          heading="Transport Charge Form"
          open={open}
          onClick={() => {
            setOpen(false);
            setId("");
          }}
        >
          <TransportChargeForm
            onClick={() => {
              setOpen(false);
              setId("");
            }}
            id={id}
          />
        </CustomizedModal>
      )}
      {deleteModal && (
        <DeleteModal
          isIcon={true}
          Icon={ErrorOutlineOutlinedIcon}
          open={deleteModal}
          onClick={() => {
            setDeleteModal(false);
            setId("");
          }}
          id={id}
          deleteData={transportChargeDelete}
          userModule="grade"
        />
      )}
    </Box>
  );
};

export default TransportChargeModule;
